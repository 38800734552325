// import React, { useState, useEffect } from "react";
// import { Link, useLocation } from "react-router-dom";
// import { useTranslation } from "react-i18next";
// import { motion, AnimatePresence } from "framer-motion";
// import mclogo from "../Images/Logos/White5.png"; // Logo de MC
// import mclogo2 from "../Images/Logos/original.png"; // Logo de MC

// export default function NavBar({ activeLink, setActiveLink }) {
//   const [scrollPosition, setScrollPosition] = useState(0);
//   const [open, setOpen] = useState(false);
//   const location = useLocation();
//   const isHomepage = location.pathname === "/";
//   const { t, i18n } = useTranslation();

//   const toggleMenu = () => {
//     setOpen(!open);
//   };

//   useEffect(() => {
//     if (isHomepage) {
//       const handleScroll = () => {
//         const position = window.scrollY;
//         setScrollPosition(position);
//       };

//       window.addEventListener("scroll", handleScroll, { passive: true });

//       return () => {
//         window.removeEventListener("scroll", handleScroll);
//       };
//     }
//   }, [isHomepage]);

//   const navbarClass = isHomepage
//     ? scrollPosition >= 100
//       ? "top-0 w-[100%] fixed z-20 h-20 bg-white backdrop-blur-md px-10 sm:px-10 md:px-20 lg:px-30 xl:px-30 2xl:px-40 text-black "
//       : `top-0 w-[100%] fixed z-20 h-20 bg-slate/70 px-10 sm:px-10 md:px-20 lg:px-30 xl:px-30 2xl:px-40 ${
//           open ? "bg-white text-black" : "lg:text-white text-white"
//         }`
//     : "top-0 w-[100%] fixed z-20 h-20 bg-white px-10 sm:px-10 md:px-20 lg:px-30 xl:px-30 2xl:px-40 text-black";

//   const imageClass = isHomepage
//     ? open
//       ? scrollPosition >= 100
//         ? ""
//         : ""
//       : scrollPosition >= 100
//       ? mclogo2
//       : mclogo
//     : open
//     ? ""
//     : mclogo2;

//   const hrClass = isHomepage
//     ? open
//       ? "hidden"
//       : scrollPosition >= 100
//       ? "hidden"
//       : "bg-white h-0.2 w-[100%]"
//     : "hidden";

//   const Links = [
//     { name: t("us"), link: "/nosotros" },
//     { name: t("services"), link: "/servicios" },
//     { name: t("agents"), link: "/agentes" },
//     { name: t("news"), link: "/noticias" },
//     // { name: t("tracking"), link: "/rastreo" },
//     { name: t("myaccount"), link: "https://my.mclogs.com/auth/login" },
//   ];

//   const handleLinkClick = (link) => {
//     setActiveLink(link);
//     setOpen(false);
//   };

//   return (
//     <header className={navbarClass}>
//       <div className="flex md:flex items-center justify-between h-full">
//         <div className="block flex-none h-full w-auto items-center justify-center pt-2">
//           <a href="/" className="text-3xl ">
//             <img className="pt-2 h-auto w-32" src={imageClass} alt="" />
//           </a>
//         </div>
//         <div className="flex text-2xl cursor-pointer xl:hidden" onClick={toggleMenu}>
//           <span>
//             {open ? (
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 strokeWidth={1.5}
//                 stroke="currentColor"
//                 className="size-6"
//               >
//                 <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
//               </svg>
//             ) : (
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 strokeWidth={1.5}
//                 stroke="currentColor"
//                 className="size-6"
//               >
//                 <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
//               </svg>
//             )}
//           </span>
//         </div>
//         <div className="hidden xl:flex items-center">
//           <ul className="flex space-x-4 list-none">
//             {Links.map((link) => (
//               <Link
//                 key={link.name}
//                 to={link.link}
//                 className={`xl:hover:bg-transparent hover:bg-gray-100 flex xl:py-0 py-4 px-4 items-center rounded-md border-gray-300 cursor-pointer ${
//                   activeLink === link.link ? "text-oldgold" : ""
//                 }`}
//                 onClick={() => handleLinkClick(link.link)}
//               >
//                 <li>{link.name}</li>
//               </Link>
//             ))}
//           </ul>
//         </div>
//         <AnimatePresence>
//           {open && (
//             <motion.ul
//               initial={{ opacity: 0, y: -20 }}
//               animate={{ opacity: 1, y: 0 }}
//               exit={{ opacity: 0, y: -20 }}
//               transition={{ duration: 0.3 }}
//               className={` flex-none z-[-1] top-8 xl:top-20 xl:h-auto items-center left-0 w-full xl:w-auto xl:flex xl:items-center xl:pb-0 xl:pl-0 py-8 xl:py-0 px-10 sm:px-10 md:px-20 xl:px-0 2xl:px-0 xl:static absolute bg-white xl:bg-transparent text-black text-2xl font-medium list-none`}
//             >
//               {Links.map((link) => (
//                 <Link
//                   key={link.name}
//                   to={link.link}
//                   className={`xl:hover:bg-transparent hover:bg-gray-100 flex xl:py-0 py-4 px-4 items-center rounded-md border-gray-300 cursor-pointer ${
//                     activeLink === link.link ? "text-oldgold" : ""
//                   }`}
//                   onClick={() => handleLinkClick(link.link)}
//                 >
//                   <li>{link.name}</li>
//                 </Link>
//               ))}
//             </motion.ul>
//           )}
//         </AnimatePresence>
//       </div>
//       <div>
//         <hr className={hrClass} />
//       </div>
//     </header>
//   );
// }


import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import mclogo from "../Images/Logos/White5.png"; // Logo de MC
import mclogo2 from "../Images/Logos/original.png"; // Logo de MC

export default function NavBar({ activeLink, setActiveLink }) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [open, setOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false); // State for dropdown
  const location = useLocation();
  const isHomepage = location.pathname === "/";
  const { t, i18n } = useTranslation();

  const toggleMenu = () => {
    setOpen(!open);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen); // Toggle dropdown state
  };

  useEffect(() => {
    if (isHomepage) {
      const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
      };

      window.addEventListener("scroll", handleScroll, { passive: true });

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isHomepage]);

  const navbarClass = isHomepage
    ? scrollPosition >= 100
      ? "top-0 w-[100%] fixed z-20 h-20 bg-white backdrop-blur-md px-10 sm:px-10 md:px-20 lg:px-30 xl:px-30 2xl:px-40 text-black "
      : `top-0 w-[100%] fixed z-20 h-20 bg-slate/70 px-10 sm:px-10 md:px-20 lg:px-30 xl:px-30 2xl:px-40 ${
          open ? "bg-white text-black" : "lg:text-white text-white"
        }`
    : "top-0 w-[100%] fixed z-20 h-20 bg-white px-10 sm:px-10 md:px-20 lg:px-30 xl:px-30 2xl:px-40 text-black";

    const imageClass = isHomepage
         ? open
           ? scrollPosition >= 100
             ? ""
             : ""
         : scrollPosition >= 100
           ? mclogo2
         : mclogo
        : open
        ? ""
        : mclogo2;

  const hrClass = isHomepage
    ? open
      ? "hidden"
      : scrollPosition >= 100
      ? "hidden"
      : "bg-white h-0.2 w-[100%]"
    : "hidden";

  const Links = [
    { name: t("us"), link: "/nosotros" },
    { name: t("services"), link: "/servicios" },
    { name: t("agents"), link: "/agentes" },
    { name: t("news"), link: "/noticias" },
    { name: t("myaccount"), link: "https://my.mclogs.com/auth/login" },
  ];

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setOpen(false);
  };

  return (
    <header className={navbarClass}>
      <div className="flex md:flex items-center justify-between h-full">
        <div className="block flex-none h-full w-auto items-center justify-center pt-2">
          <a href="/" className="text-3xl ">
            <img className="pt-2 h-auto w-32" src={imageClass}/>
          </a>
        </div>
        <div className="flex text-2xl cursor-pointer xl:hidden" onClick={toggleMenu}>
          <span>
            {open ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            )}
          </span>
        </div>
        <div className="hidden xl:flex items-center">
          <ul className="flex space-x-4 list-none">
            <li
              className="relative"
              onMouseEnter={() => setDropdownOpen(true)}
              onMouseLeave={() => setDropdownOpen(false)}
            >
              <span className="cursor-pointer flex items-center">
                {t("Sobre Nosotros")}{" "}
                <svg
                  className="ml-1 h-4 w-4"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7"></path>
                </svg>
              </span>
              <AnimatePresence>
                {dropdownOpen && (
                  <motion.ul
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                    className="absolute left-0 mt-2 w-40 bg-white border border-gray-200 rounded-md shadow-lg"
                  >
                    {Links.slice(0, 3).map((link) => (
                      <Link
                        key={link.name}
                        to={link.link}
                        className={`block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100 ${
                          activeLink === link.link ? "text-oldgold" : ""
                        }`}
                        onClick={() => handleLinkClick(link.link)}
                      >
                        {link.name}
                      </Link>
                    ))}
                  </motion.ul>
                )}
              </AnimatePresence>
            </li>
            {Links.slice(3).map((link) => (
              <Link
                key={link.name}
                to={link.link}
                className={`xl:hover:bg-transparent hover:bg-gray-100 flex xl:py-0 py-4 px-4 items-center rounded-md border-gray-300 cursor-pointer ${
                  activeLink === link.link ? "text-oldgold" : ""
                }`}
                onClick={() => handleLinkClick(link.link)}
              >
                <li>{link.name}</li>
              </Link>
            ))}
          </ul>
        </div>
        <AnimatePresence>
          {open && (
            <motion.ul
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className={`flex-none z-[-1] top-8 xl:top-20 xl:h-auto items-center left-0 w-full xl:w-auto xl:flex xl:items-center xl:pb-0 xl:pl-0 py-8 xl:py-0 px-10 sm:px-10 md:px-20 xl:px-0 2xl:px-0 xl:static absolute bg-white xl:bg-transparent text-black text-2xl font-medium list-none`}
            >
              {Links.map((link) => (
                <Link
                  key={link.name}
                  to={link.link}
                  className={`xl:hover:bg-transparent hover:bg-gray-100 flex xl:py-0 py-4 px-4 items-center rounded-md border-gray-300 cursor-pointer ${
                    activeLink === link.link ? "text-oldgold" : ""
                  }`}
                  onClick={() => handleLinkClick(link.link)}
                >
                  <li>{link.name}</li>
                </Link>
              ))}
            </motion.ul>
          )}
        </AnimatePresence>
      </div>
      <div>
        <hr className={hrClass} />
      </div>
    </header>
  );
}
